import { useCurrencyBalance } from '@state/wallet/hooks';
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo';
import CurrencySearchModal from '@components/SearchModal/CurrencySearchModal';
import { useModal } from '@vaporfi/hooks';
import { useCommify } from '@vaporfi/hooks';
import useActiveWagmi from '@hooks/useActiveWagmi';
import {
  CurrencyInputPanel as UiKitCurrencyInputPanel,
  CurrencyInputPanelProps,
} from '@vaporfi/uikit';

export default function CurrencyInputPanel({
  balanceOverrides = null,
  currency,
  disableCurrencySelect = false,
  disabledInput,
  disabledSelect,
  error,
  hideBalance = false,
  id,
  label,
  onCurrencySelect,
  onHalf,
  onInputBlur,
  onMax,
  onUserInput,
  otherCurrency,
  pair = null, // used for double token logo
  showCommonBases,
  showMaxButton = true,
  value,
}: Omit<
  CurrencyInputPanelProps,
  'balance' | 'logo' | 'account' | 'showActionButtons'
>) {
  const { account } = useActiveWagmi();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined,
  );

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  );
  const commify = useCommify();
  const balance = selectedCurrencyBalance
    ? commify(selectedCurrencyBalance.toSignificant(4))
    : `Loading...`;
  const logo = pair ? (
    <DoubleCurrencyLogo
      currency0={pair.token0}
      currency1={pair.token1}
      size={16}
      margin
    />
  ) : currency ? (
    <CurrencyLogo currency={currency} size={20} />
  ) : null;

  const showActionButtons =
    account && currency && !disabledInput && showMaxButton && label !== 'To';
  return (
    <UiKitCurrencyInputPanel
      {...{
        account,
        balance,
        balanceOverrides,
        currency,
        disableCurrencySelect,
        disabledInput,
        disabledSelect,
        error,
        hideBalance,
        id,
        label,
        logo,
        onCurrencySelect,
        onHalf,
        onInputBlur,
        onMax,
        onPresentCurrencyModal,
        onUserInput,
        otherCurrency,
        pair,
        showHalfButton: onHalf && showActionButtons,
        showMaxButton: onMax && showActionButtons,
        value,
      }}
    />
  );
}
