import { Address } from 'viem';
import { useSingleCallResult } from '../state/multicall/hooks';
import { Token, CurrencyAmount, Currency } from '@vapordex/sdk';
import { useMemo } from 'react';
import { ERC20ABI } from '@config/abi/erc20ABI';

function useTokenAllowance(
  token?: Token,
  owner?: string,
  spender?: string,
): CurrencyAmount<Currency> | undefined {
  const inputs = useMemo(() => [owner, spender], [owner, spender]);
  const allowance = useSingleCallResult(
    token?.address as Address,
    ERC20ABI,
    'allowance',
    inputs,
  ).result;

  return useMemo(
    () =>
      token && typeof allowance === 'bigint'
        ? CurrencyAmount.fromRawAmount(token, allowance.toString())
        : undefined,
    [token, allowance],
  );
}

export default useTokenAllowance;
