import React, { useState } from 'react';
import ManageLists from './ManageLists';
import ManageTokens from './ManageTokens';
import { CurrencyModalView } from './types';
import { TokenList } from '@vapordex/tokenlist';
import { Token } from '@vapordex/sdk';
import { CombinedListsTokensUI } from '@vaporfi/features';

interface ManageProps {
  setModalView: (view: CurrencyModalView) => void;
  setImportToken: (token: Token) => void;
  setImportList: (list: TokenList) => void;
  setListUrl: (url: string) => void;
}

const Manage: React.FC<ManageProps> = ({
  setImportList,
  setImportToken,
  setListUrl,
  setModalView,
}) => {
  const [showLists, setShowLists] = useState(true);

  const handleListsClick = () => setShowLists(true);
  const handleTokensClick = () => setShowLists(false);

  return (
    <div className="flex flex-col p-4" style={{ marginTop: '-30px' }}>
      <div className="mb-6">
        <CombinedListsTokensUI
          showLists={showLists}
          onListsClick={handleListsClick}
          onTokensClick={handleTokensClick}
        />
      </div>
      <div className="flex-grow">
        {showLists ? (
          <ManageLists
            setModalView={setModalView}
            setImportList={setImportList}
            setListUrl={setListUrl}
          />
        ) : (
          <ManageTokens
            setModalView={setModalView}
            setImportToken={setImportToken}
          />
        )}
      </div>
    </div>
  );
};

export default Manage;
