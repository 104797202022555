import {
  useSingleContractMultipleData,
  useMultipleContractSingleData,
} from '../multicall/hooks';
import { Currency, CurrencyAmount, Token } from '@vapordex/sdk';
import { ERC20ABI } from 'config/abi/erc20';
import { useAllTokens } from 'hooks/Tokens';
import useActiveWagmi from 'hooks/useActiveWagmi';
import { useMemo } from 'react';
import { isAddress } from 'utils';
import { Address, useBalance } from 'wagmi';
import { JSBI } from '@vapordex/sdk';
import { parseUnits, toHex } from 'viem';
import { getMulticallAddress } from '@utils/addressHelpers';
import { MulticallABI } from '@config/abi/Multicall';

export const addNetworkToWallet = async (chainData) => {
  debugger;
  if (chainData.length === 0) return;

  await window.ethereum?.request({
    jsonrpc: '2.0',
    method: 'wallet_addEthereumChain',
    params: [
      {
        blockExplorerUrls: chainData[0].blockExplorerUrls,
        chainId: toHex(chainData[0].id),
        chainName: chainData[0].name,
        nativeCurrency: chainData[0].nativeCurrency,
        rpcUrls: [chainData[0].rpcUrls.default],
      },
    ],
  });
};

export const addTokenToWallet = (currr) => {
  return window.ethereum?.request({
    method: 'wallet_watchAsset',
    params: {
      options: {
        address: currr.address,
        decimals: currr.decimals,
        image: currr.logoURI,
        symbol: currr.symbol,
      },
      type: 'ERC20',
    },
  });
};

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: Address,
  tokens?: (Token | undefined)[],
): [{ [tokenAddress: Address]: CurrencyAmount<Token> | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () =>
      tokens?.filter(
        (t?: Token): t is Token => isAddress(t?.address) !== false,
      ) ?? [],
    [tokens],
  );

  const validatedTokenAddresses = useMemo(
    () => validatedTokens.map<Address>((vt) => vt.address),
    [validatedTokens],
  );

  const balances = useMultipleContractSingleData(
    validatedTokenAddresses,
    ERC20ABI,
    'balanceOf',
    useMemo(() => [address], [address]),
  );

  const anyLoading: boolean = useMemo(
    () => balances.some((callState) => callState.loading),
    [balances],
  );

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{
              [tokenAddress: string]: CurrencyAmount<Token> | undefined;
            }>((memo, token, i) => {
              const value = balances?.[i]?.result;
              const amount = value?.toString() ?? undefined;
              if (amount) {
                memo[token.address] = CurrencyAmount.fromRawAmount(
                  token,
                  amount,
                );
              }
              return memo;
            }, {})
          : {},
      [address, validatedTokens, balances],
    ),
    anyLoading,
  ];
}

export function useTokenBalances(
  address?: Address,
  tokens?: (Token | undefined)[],
): { [tokenAddress: Address]: CurrencyAmount<Token> | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0];
}

// get the balance for a single token/account combo
export function useTokenBalance(
  account?: Address,
  token?: Token,
): CurrencyAmount<Token> | undefined {
  const tokenBalances = useTokenBalances(account, [token]);
  if (!token) return undefined;
  return tokenBalances[token.address];
}

/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useNativeCurrencyBalances(
  uncheckedAddresses?: (string | undefined)[],
): {
  [address: string]: CurrencyAmount<Currency> | undefined;
} {
  const { chainId, nativeCurrency } = useActiveWagmi();

  const validAddressInputs: [string][] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is Address => a !== false)
            .sort()
            .map((addr) => [addr])
        : [],
    [uncheckedAddresses],
  );

  const results = useSingleContractMultipleData(
    getMulticallAddress(),
    MulticallABI,
    'getEthBalance',
    validAddressInputs,
  );

  return useMemo(
    () =>
      validAddressInputs.reduce<{
        [address: string]: CurrencyAmount<Currency>;
      }>((memo, [address], i) => {
        const value = results?.[i]?.result?.[0];
        if (value && chainId)
          memo[address] = CurrencyAmount.fromRawAmount(
            nativeCurrency,
            JSBI.BigInt(value.toString()),
          );
        return memo;
      }, {}),
    [validAddressInputs, results, chainId, nativeCurrency],
  );
}

export function useCurrencyBalances(
  account?: Address,
  currencies?: (Currency | undefined)[],
): (CurrencyAmount<Currency> | undefined)[] {
  const { nativeCurrency } = useActiveWagmi();
  const tokens = useMemo(
    () =>
      currencies?.filter(
        (currency): currency is Token => currency instanceof Token,
      ) ?? [],
    [currencies],
  );

  const tokenBalances = useTokenBalances(account, tokens);

  const { data: ethBalance } = useBalance({ address: account });

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return;
        if (currency instanceof Token) return tokenBalances[currency.address];
        if (currency?.isNative && ethBalance)
          return CurrencyAmount.fromRawAmount(
            nativeCurrency,
            JSBI.BigInt(parseUnits(ethBalance.formatted, 18)?.toString()),
          );
        return;
      }) ?? [],
    [currencies, account, tokenBalances, ethBalance, nativeCurrency],
  );
}

export function useCurrencyBalance(
  account?: Address,
  currency?: Currency,
): CurrencyAmount<Currency> | undefined {
  return useCurrencyBalances(account, [currency])[0];
}

// mimics useAllBalances
export function useAllTokenBalances(): {
  [tokenAddress: string]: CurrencyAmount<Currency> | undefined;
} {
  const { account, chainId } = useActiveWagmi();
  const allTokens = useAllTokens(chainId);
  const allTokensArray = useMemo(
    () => Object.values(allTokens ?? {}),
    [allTokens],
  );
  const balances = useTokenBalances(account ?? undefined, allTokensArray);
  return balances ?? {};
}
