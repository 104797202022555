import Button from '@components/Button';
import { AutoColumn } from '@components/Layout/Column';
import { AutoRow, RowFixed } from '@components/Layout/Row';
import { ListLogo } from '@components/Logo';
import CurrencyLogo from '@components/Logo/CurrencyLogo';
import {
  Text,
  CheckmarkCircleIcon,
  useMatchBreakpointsContext,
} from '@vapordex/uikit';
import { useIsUserAddedToken, useIsTokenActive } from 'hooks/Tokens';
import useActiveWagmi from 'hooks/useActiveWagmi';
import { CSSProperties } from 'react';
import { useCombinedInactiveList } from 'state/lists/hooks';
import styled from 'styled-components';
import { Token } from '@vapordex/sdk';
const TokenSection = styled.div<{ dim?: boolean }>`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns: auto minmax(auto, 1fr) auto;
  grid-gap: 10px;
  align-items: center;

  opacity: ${({ dim }) => (dim ? '0.4' : '1')};

  ${({ theme }) => theme.mediaQueries.md} {
    grid-gap: 16px;
  }
`;

const CheckIcon = styled(CheckmarkCircleIcon)`
  height: 16px;
  width: 16px;
  margin-right: 6px;
  stroke: ${({ theme }) => theme.colors.success};
`;

const NameOverflow = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  font-size: 12px;
`;

export default function ImportRow({
  dim,
  setImportToken,
  showImportView,
  style,
  token,
}: {
  token: Token;
  style?: CSSProperties;
  dim?: boolean;
  showImportView: () => void;
  setImportToken: (token: Token) => void;
}) {
  // globals
  const { chainId } = useActiveWagmi();
  const { isMobile } = useMatchBreakpointsContext();

  // check if token comes from list
  const inactiveTokenList = useCombinedInactiveList();
  const list = chainId && inactiveTokenList?.[chainId]?.[token.address]?.list;

  // check if already active on list or local storage tokens
  const isAdded = useIsUserAddedToken(token);
  const isActive = useIsTokenActive(token);

  return (
    <TokenSection data-testid="import-token-section" style={style}>
      <CurrencyLogo
        currency={token}
        size={isMobile ? 20 : 24}
        style={{ opacity: dim ? '0.6' : '1' }}
      />
      <AutoColumn gap="4px" style={{ opacity: dim ? '0.6' : '1' }}>
        <AutoRow>
          <Text data-testid="import-token-symbol" mr="8px">
            {token.symbol}
          </Text>
          <Text color="textDisabled">
            <NameOverflow data-testid="import-token-name" title={token.name}>
              {token.name}
            </NameOverflow>
          </Text>
        </AutoRow>
        {list && list.logoURI && (
          <RowFixed>
            <Text
              fontSize={isMobile ? '10px' : '14px'}
              mr="4px"
              color="textSubtle"
            >
              {'via'} {list.name}
            </Text>
            <ListLogo logoURI={list.logoURI} size={12} />
          </RowFixed>
        )}
      </AutoColumn>
      {!isActive && !isAdded ? (
        <Button
          width="fit-content"
          onClick={() => {
            if (setImportToken) {
              setImportToken(token);
            }
            showImportView();
          }}
        >
          {'Import'}
        </Button>
      ) : (
        <RowFixed style={{ minWidth: 'fit-content' }}>
          <CheckIcon />
          <Text color="success">Active</Text>
        </RowFixed>
      )}
    </TokenSection>
  );
}
