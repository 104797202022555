import { Field } from '../state/swap/actions';
import { getContract } from './index';
import { VaporDEXRouter02ABI } from 'config/abi/VaporDEXRouter02';
import {
  BIPS_BASE,
  ONE_HUNDRED_PERCENT,
  INPUT_FRACTION_AFTER_FEE,
  BLOCKED_PRICE_IMPACT_NON_EXPERT,
  ALLOWED_PRICE_IMPACT_HIGH,
  ALLOWED_PRICE_IMPACT_MEDIUM,
  ALLOWED_PRICE_IMPACT_LOW,
} from 'config/constants/exchange';
import { getChainIdCookie } from 'config/constants/networks';
import {
  CurrencyAmount,
  Currency,
  Fraction,
  Percent,
  TradeType,
} from '@vapordex/sdk';
import { Trade } from '@vapordex/sdk';
import { JSBI, ROUTER_ADDRESS_MAP } from '@vapordex/sdk';
import { PublicClient, WalletClient } from 'wagmi';
import { getPublicClient } from '@wagmi/core';
// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), BIPS_BASE);
}

const ONE = new Fraction(1, 1);

export function calculateSlippageAmount(
  value: CurrencyAmount<Currency>,
  slippage: Percent,
): [JSBI, JSBI] {
  if (slippage.lessThan(0) || slippage.greaterThan(ONE))
    throw new Error('Unexpected slippage');
  return [
    value.multiply(ONE.subtract(slippage)).quotient,
    value.multiply(ONE.add(slippage)).quotient,
  ];
}

// export function calculateSlippageAmount(
//   value: CurrencyAmount<Currency>,
//   slippage: number,
// ): [JSBI, JSBI] {
//   if (slippage < 0 || slippage > 10000) {
//     throw Error(`Unexpected slippage value: ${slippage}`)
//   }
//   return [
//     JSBI.divide(
//       JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)),
//       BIPS_BASE,
//     ),
//     JSBI.divide(
//       JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)),
//       BIPS_BASE,
//     ),
//   ]
// }

// account is optional
export function getRouterContract(
  signer?: WalletClient,
  publicClient?: PublicClient,
) {
  const chainId = signer?.chain?.id || +getChainIdCookie();
  return getContract<typeof VaporDEXRouter02ABI>(
    ROUTER_ADDRESS_MAP[chainId],
    VaporDEXRouter02ABI,
    signer,
    publicClient || getPublicClient({ chainId }),
  );
}

// computes price breakdown for the trade
export function computeTradePriceBreakdown(
  trade?: Trade<Currency, Currency, TradeType> | null,
): {
  priceImpactWithoutFee: Percent | undefined;
  realizedLPFee: CurrencyAmount<Currency> | undefined | null;
} {
  // for each hop in our trade, take away the x*y=k price impact from 0.3% fees
  // e.g. for 3 tokens/2 hops: 1 - ((1 - .03) * (1-.03))
  const realizedLPFee = trade
    ? ONE_HUNDRED_PERCENT.subtract(
        trade.route.pairs.reduce<Fraction>(
          (currentFee: Fraction): Fraction =>
            currentFee.multiply(INPUT_FRACTION_AFTER_FEE),
          ONE_HUNDRED_PERCENT,
        ),
      )
    : undefined;

  // remove lp fees from price impact
  const priceImpactWithoutFeeFraction =
    trade && realizedLPFee
      ? trade.priceImpact.subtract(realizedLPFee)
      : undefined;

  // the x*y=k impact
  const priceImpactWithoutFeePercent = priceImpactWithoutFeeFraction
    ? new Percent(
        priceImpactWithoutFeeFraction?.numerator,
        priceImpactWithoutFeeFraction?.denominator,
      )
    : undefined;

  // the amount of the input that accrues to LPs
  const realizedLPFeeAmount =
    realizedLPFee &&
    trade &&
    CurrencyAmount.fromRawAmount(
      trade.inputAmount.currency,
      realizedLPFee.multiply(trade.inputAmount.quotient).quotient,
    );

  return {
    priceImpactWithoutFee: priceImpactWithoutFeePercent,
    realizedLPFee: realizedLPFeeAmount,
  };
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade<Currency, Currency, TradeType> | undefined,
  allowedSlippage: number,
): { [field in Field]?: CurrencyAmount<Currency> } {
  const pct = basisPointsToPercent(allowedSlippage);
  return {
    [Field.INPUT]: trade?.maximumAmountIn(pct),
    [Field.OUTPUT]: trade?.minimumAmountOut(pct),
  };
}

export function warningSeverity(
  priceImpact: Percent | undefined,
): 0 | 1 | 2 | 3 | 4 {
  if (!priceImpact?.lessThan(BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_HIGH)) return 3;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_MEDIUM)) return 2;
  if (!priceImpact?.lessThan(ALLOWED_PRICE_IMPACT_LOW)) return 1;
  return 0;
}

export function formatExecutionPrice(
  trade?: Trade<Currency, Currency, TradeType>,
  inverted?: boolean,
): string {
  if (!trade) {
    return '';
  }
  return inverted
    ? `${trade.executionPrice.invert().toSignificant(6)} ${
        trade.inputAmount.currency.symbol
      } / ${trade.outputAmount.currency.symbol}`
    : `${trade.executionPrice.toSignificant(6)} ${
        trade.outputAmount.currency.symbol
      } / ${trade.inputAmount.currency.symbol}`;
}
