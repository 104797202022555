import Button from '@components/Button';
import { AutoColumn } from '@components/Layout/Column';
import { ListLogo } from '@components/Logo';
import { Token, Currency } from '@vapordex/sdk';
import {
  Text,
  ErrorIcon,
  Flex,
  Message,
  Checkbox,
  Link,
  Tag,
  Grid,
} from '@vapordex/uikit';
import { useState } from 'react';
import { useCombinedInactiveList } from 'state/lists/hooks';
import { useAddUserToken } from 'state/user/hooks';
import { ExplorerDataType, getExplorerLink, getExplorerName } from 'utils';
import truncateHash from 'utils/truncateHash';
import { useNetwork } from 'wagmi';

interface ImportProps {
  tokens: Token[];
  handleCurrencySelect?: (currency: Currency) => void;
}

function ImportToken({ handleCurrencySelect, tokens }: ImportProps) {
  const { chain } = useNetwork();

  const [confirmed, setConfirmed] = useState(false);

  const addToken = useAddUserToken();

  // use for showing import source on inactive tokens
  const inactiveTokenList = useCombinedInactiveList();

  return (
    <AutoColumn gap="lg">
      <Message variant="warning">
        <Text>
          {
            'Anyone can create a ERC20 token on Avalanche with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.'
          }
          <br />
          <br />
          {
            'If you purchase an arbitrary token, you may be unable to sell it back.'
          }
        </Text>
      </Message>

      {tokens.map((token) => {
        const list =
          chain?.id && inactiveTokenList?.[chain?.id]?.[token.address]?.list;
        const address = token.address ? `${truncateHash(token.address)}` : null;
        return (
          <Grid
            key={token.address}
            gridTemplateRows="1fr 1fr 1fr"
            gridGap="4px"
          >
            {list === undefined ? (
              <Tag
                variant="failure"
                outline
                scale="sm"
                startIcon={<ErrorIcon color="failure" />}
              >
                {'Unknown Source'}
              </Tag>
            ) : (
              <Tag
                variant="success"
                outline
                scale="sm"
                startIcon={
                  list.logoURI && <ListLogo logoURI={list.logoURI} size={12} />
                }
              >
                {'via'} {list.name}
              </Tag>
            )}
            <Flex alignItems="center">
              <Text mr="8px">{token.name}</Text>
              <Text>({token.symbol})</Text>
            </Flex>
            {chain?.id && (
              <Flex justifyContent="space-between" width="100%">
                <Text mr="4px">{address}</Text>
                <Link
                  href={getExplorerLink(
                    token.address,
                    ExplorerDataType.ADDRESS,
                    chain,
                  )}
                  external
                >
                  {`View on ${getExplorerName(chain)}`}
                </Link>
              </Flex>
            )}
          </Grid>
        );
      })}

      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" onClick={() => setConfirmed(!confirmed)}>
          <Checkbox
            scale="sm"
            name="confirmed"
            type="checkbox"
            checked={confirmed}
            onChange={() => setConfirmed(!confirmed)}
          />
          <Text ml="8px" style={{ userSelect: 'none' }}>
            {'I understand'}
          </Text>
        </Flex>
        {/* TODO: fix color */}
        <Button
          variant="danger"
          disabled={!confirmed}
          onClick={() => {
            for (const token of tokens) addToken(token);
            if (handleCurrencySelect) {
              handleCurrencySelect(tokens[0]);
            }
          }}
          className=".token-dismiss-button"
        >
          {'Import'}
        </Button>
      </Flex>
    </AutoColumn>
  );
}

export default ImportToken;
