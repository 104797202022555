import { Currency, CurrencyAmount, Token } from '@vapordex/sdk';
import { supportedChainId } from './supportedChainId';
import { WRAPPED_NATIVE_CURRENCY, nativeOnChain } from '@vaporfi/utils';
import { ChainId } from '@vapordex/sdk';

export function wrappedCurrency(
  currency: Currency | undefined,
  chainId: ChainId | undefined,
): Currency | undefined {
  return chainId && currency?.isNative
    ? nativeOnChain(chainId)
    : currency instanceof Token
    ? currency
    : undefined;
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount<Currency> | undefined,
  chainId: ChainId | undefined,
): CurrencyAmount<Currency> | undefined {
  const token =
    currencyAmount && chainId
      ? wrappedCurrency(currencyAmount.currency, chainId)
      : undefined;
  return token && currencyAmount
    ? CurrencyAmount.fromRawAmount(token, currencyAmount.quotient.toString())
    : undefined;
}

export function unwrappedToken(currency: Currency): Currency {
  const formattedChainId = supportedChainId(currency?.chainId);

  if (
    formattedChainId &&
    WRAPPED_NATIVE_CURRENCY[formattedChainId]?.equals(currency)
  )
    return nativeOnChain(currency.chainId);
  return currency;
}
