import Image from 'next/image';

export default function ListLogo({
  alt,
  logoURI,
  size = 24,
  style,
}: {
  logoURI: string;
  size?: number;
  style?: React.CSSProperties;
  alt?: string;
}) {
  return (
    <Image alt={alt} width={size} height={size} src={logoURI} style={style} />
  );
}
