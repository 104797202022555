import { mainnetTokens, testnetTokens } from './tokens';
import { ChainTokenList } from './types';
import { ChainId, JSBI } from '@vapordex/sdk';
import { Percent } from '@vapordex/sdk';
import { Token } from '@vapordex/sdk';
// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [ChainId.AVALANCHE]: [
    mainnetTokens[ChainId.AVALANCHE].wavax,
    mainnetTokens[ChainId.AVALANCHE].usdc,
    mainnetTokens[ChainId.AVALANCHE].usdt,
  ],
  [ChainId.AVALANCHE_TESTNET]: [
    testnetTokens[ChainId.AVALANCHE_TESTNET].wavax,
    testnetTokens[ChainId.AVALANCHE_TESTNET].usdc,
  ],
};

/**
 * Additional bases for specific tokens
 * @example { [WBTC.address]: [renBTC], [renBTC.address]: [WBTC] }
 */
export const ADDITIONAL_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {
  [ChainId.AVALANCHE]: {},
};

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 * @example [AMPL.address]: [DAI, WETH[ChainId.AVALANCHE]]
 */
export const CUSTOM_BASES: {
  [chainId in ChainId]?: { [tokenAddress: string]: Token[] };
} = {
  [ChainId.AVALANCHE]: {},
};

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  [ChainId.AVALANCHE]: [mainnetTokens[ChainId.AVALANCHE].usdc],
  [ChainId.AVALANCHE_TESTNET]: [testnetTokens[ChainId.AVALANCHE_TESTNET].wavax],
  [ChainId.TELOS]: [mainnetTokens[ChainId.TELOS].wtelos],
  [ChainId.TELOS_TESTNET]: [testnetTokens[ChainId.TELOS_TESTNET].wtelos],
};

export const BIG_INT_ZERO = JSBI.BigInt(0);
export const BIG_INT_TEN = JSBI.BigInt(10);

// one basis point
export const BIPS_BASE = JSBI.BigInt(10_000);
export const ONE_BIPS = new Percent(JSBI.BigInt(1), BIPS_BASE);
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(
  JSBI.BigInt(100),
  BIPS_BASE,
); // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(
  JSBI.BigInt(300),
  BIPS_BASE,
); // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(
  JSBI.BigInt(500),
  BIPS_BASE,
); // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(
  JSBI.BigInt(1000),
  BIPS_BASE,
); // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(
  JSBI.BigInt(1500),
  BIPS_BASE,
); // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_BNB: JSBI = JSBI.exponentiate(BIG_INT_TEN, JSBI.BigInt(16)); // .01 BNB
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(
  JSBI.BigInt(50),
  BIPS_BASE,
);

export const ZERO_PERCENT = new Percent('0');
export const ONE_HUNDRED_PERCENT = new Percent('1');

export const BASE_FEE = new Percent(JSBI.BigInt(29), BIPS_BASE);
export const INPUT_FRACTION_AFTER_FEE = ONE_HUNDRED_PERCENT.subtract(BASE_FEE);

// AVAX
export const DEFAULT_INPUT_CURRENCY = {
  [ChainId.AVALANCHE]: 'AVAX',
  [ChainId.AVALANCHE_TESTNET]: 'AVAX',
  [ChainId.TELOS]: 'TLOS',
  [ChainId.TELOS_TESTNET]: 'TLOS',
};
// USDC
export const DEFAULT_OUTPUT_CURRENCY = {
  [ChainId.AVALANCHE]: '0x7bddaF6DbAB30224AA2116c4291521C7a60D5f55',
  [ChainId.AVALANCHE_TESTNET]: '0x0914aFfEbBAe91fB410A3Cd85a0C7AC740b030cF',
  [ChainId.TELOS]: mainnetTokens[ChainId.TELOS].usdt?.address,
};
