import CurrencyLogo from './CurrencyLogo';
import { Currency } from '@vapordex/sdk';
import { IconButton, SyncAltIcon } from '@vapordex/uikit';
import { Avatar } from '@vaporfi/uikit';
import styled from 'styled-components';

const Wrapper = styled.div<{ margin: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: ${({ margin }) => margin && '4px'};
`;

interface DoubleCurrencyLogoProps {
  margin?: boolean;
  size?: number;
  currency0?: Currency;
  currency1?: Currency;
  onSwitchTokens?: () => void;
  showName?: boolean;
}

export default function DoubleCurrencyLogo({
  currency0,
  currency1,
  margin = false,
  onSwitchTokens,
  showName,
  size = 20,
}: DoubleCurrencyLogoProps) {
  return (
    <Wrapper margin={margin}>
      {currency0 ? (
        <CurrencyLogo
          data-testid="double-currency-logo-0"
          showName={showName}
          currency={currency0}
          size={size}
          style={{ borderRadius: '50%', marginRight: '4px' }}
        />
      ) : (
        <Avatar
          alt="token0"
          className="h-[50px] w-[50px]"
          fallbackClassName="bg-neutral-400"
        />
      )}
      {onSwitchTokens && (
        <IconButton variant="text" onClick={onSwitchTokens}>
          <SyncAltIcon color="primary" />
        </IconButton>
      )}
      {currency1 ? (
        <CurrencyLogo
          data-testid="double-currency-logo-1"
          showName={showName}
          currency={currency1}
          size={size}
          style={{
            borderRadius: '50%',
            marginLeft: '-8px',
            marginRight: '8px',
          }}
        />
      ) : (
        <Avatar
          alt="token1"
          className="h-[50px] w-[50px]"
          fallbackClassName="bg-neutral-600"
        />
      )}
    </Wrapper>
  );
}
