import Column, { AutoColumn } from '../Layout/Column';
import ImportRow from './ImportRow';
import { CurrencyModalView } from './types';
import Button from '@components/Button';
import Row, { RowBetween, RowFixed } from '@components/Layout/Row';
import { CurrencyLogo } from '@components/Logo';
import { Token } from '@vapordex/sdk';
import {
  Text,
  CloseIcon,
  IconButton,
  LinkExternal,
  Input,
  Link,
} from '@vapordex/uikit';
import { useToken } from 'hooks/Tokens';
import { useRef, RefObject, useCallback, useState, useMemo } from 'react';
import { useRemoveUserAddedToken } from 'state/user/hooks';
import useUserAddedTokens from 'state/user/hooks/useUserAddedTokens';
import styled from 'styled-components';
import { ExplorerDataType, getExplorerLink, isAddress } from 'utils';
import { useNetwork } from 'wagmi';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100% - 60px);
  position: relative;
  padding-bottom: 60px;
`;

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function ManageTokens({
  setImportToken,
  setModalView,
}: {
  setModalView: (view: CurrencyModalView) => void;
  setImportToken: (token: Token) => void;
}) {
  const { chain } = useNetwork();

  const [searchQuery, setSearchQuery] = useState<string>('');

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
  }, []);

  // if they input an address, use it
  const searchToken = useToken(searchQuery);

  // all tokens for local list
  const userAddedTokens: Token[] = useUserAddedTokens();
  const removeToken = useRemoveUserAddedToken();

  const handleRemoveAll = useCallback(() => {
    if (chain?.id && userAddedTokens) {
      for (const token of userAddedTokens) {
        removeToken(chain?.id, token.address);
        continue;
      }
    }
  }, [removeToken, userAddedTokens, chain?.id]);

  const tokenList = useMemo(() => {
    return (
      chain?.id &&
      userAddedTokens.map((token) => (
        <RowBetween key={token.address} width="100%">
          <RowFixed>
            <CurrencyLogo currency={token} size={20} />
            <Link
              external
              href={getExplorerLink(
                token.address,
                ExplorerDataType.ADDRESS,
                chain,
              )}
              color="textSubtle"
              ml="10px"
            >
              {token.symbol}
            </Link>
          </RowFixed>
          <RowFixed>
            <IconButton
              variant="text"
              onClick={() => removeToken(chain?.id, token.address)}
            >
              <CloseIcon />
            </IconButton>
            <LinkExternal
              href={getExplorerLink(
                token.address,
                ExplorerDataType.ADDRESS,
                chain,
              )}
            />
          </RowFixed>
        </RowBetween>
      ))
    );
  }, [chain, userAddedTokens, removeToken]);

  const isAddressValid = searchQuery === '' || isAddress(searchQuery);

  return (
    <Wrapper>
      <Column style={{ flex: '1 1', width: '100%' }}>
        <AutoColumn gap="14px">
          <Row>
            <Input
              id="token-search-input"
              data-testid="token-search-input"
              scale="lg"
              placeholder="0x0000"
              value={searchQuery}
              autoComplete="off"
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              isWarning={!isAddressValid}
            />
          </Row>
          {!isAddressValid && (
            <Text color="failure">{'Enter valid token address'}</Text>
          )}
          {searchToken && (
            <ImportRow
              token={searchToken}
              showImportView={() => setModalView(CurrencyModalView.importToken)}
              setImportToken={setImportToken}
              style={{ height: 'fit-content' }}
            />
          )}
        </AutoColumn>
        {tokenList}
        <Footer>
          <Text
            bold
            color="textSubtle"
            data-testid="import-token-custom-token-text"
          >
            {userAddedTokens?.length}{' '}
            {userAddedTokens.length === 1 ? 'Custom Token' : 'Custom Tokens'}
          </Text>
          {userAddedTokens.length > 0 && (
            <Button variant="secondary" onClick={handleRemoveAll}>
              {'Clear all'}
            </Button>
          )}
        </Footer>
      </Column>
    </Wrapper>
  );
}
