import { Box } from '@vapordex/uikit';
import styled from 'styled-components';

const Card = styled(Box)<{
  width?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  padding: ${({ padding }) => padding ?? '1.25rem'};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius ?? '16px'};
  background-color: ${({ theme }) => theme.colors.background};
`;
export default Card;

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`;

export const LightGreyCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.background};
`;

export const DarkGrayCard = styled(Card)`
  background-color: ${({ theme }) => theme.v2.backgroundInteractive};
`;

export const DarkCard = styled(Card)`
  background-color: ${({ theme }) => theme.v2.backgroundSurface};
`;

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.dropdown};
`;
export const ErrorCard = styled(Card)`
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.failure};
`;
export const WarningCard = styled(Card)`
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.colors.warning};
`;
export const SuccessCard = styled(Card)`
  padding: 12px;
  border: 2px solid #00cd46;
`;

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.v2.deprecated_bg3};
`;
export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.v2.deprecated_yellow3};
  font-weight: 500;
`;

export const BlueCard = styled(Card)`
  background-color: ${({ theme }) => theme.v2.deprecated_primary5};
  color: ${({ theme }) => theme.v2.accentAction};
  border-radius: 12px;
`;
