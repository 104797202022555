import { AutoColumn } from '../Layout/Column';
import { AutoRow } from '../Layout/Row';
import { CurrencyLogo } from '../Logo';
import QuestionHelper from '../QuestionHelper';
import { Currency, Token } from '@vapordex/sdk';
import { ChainId } from '@vapordex/sdk';
import { Text } from '@vapordex/uikit';
import { nativeOnChain } from '@vaporfi/utils';
import { SUGGESTED_BASES } from 'config/constants/exchange';
import styled from 'styled-components';

const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid
    ${({ disable, theme }) => (disable ? 'transparent' : theme.colors.dropdown)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    cursor: ${({ disable }) => !disable && 'pointer'};
    background-color: ${({ disable, theme }) =>
      !disable && theme.colors.background};
  }

  background-color: ${({ disable, theme }) => disable && theme.colors.dropdown};
  opacity: ${({ disable }) => disable && '0.4'};
`;

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
}: {
  chainId?: ChainId;
  selectedCurrency?: Currency | null;
  onSelect: (currency: Currency) => void;
}) {
  const nativeCurrency = nativeOnChain(chainId);
  return (
    <AutoColumn gap="md">
      <AutoRow>
        <Text fontSize="14px">{'Common bases'}</Text>
        <QuestionHelper
          text={'These tokens are commonly paired with other tokens.'}
          className="ml-1"
        />
      </AutoRow>
      <AutoRow gap="auto">
        <BaseWrapper
          onClick={() => {
            if (!selectedCurrency || !selectedCurrency.isNative) {
              onSelect(nativeOnChain(chainId));
            }
          }}
          disable={selectedCurrency === nativeCurrency}
        >
          <CurrencyLogo currency={nativeCurrency} style={{ marginRight: 8 }} />
          <Text>{nativeCurrency?.symbol}</Text>
        </BaseWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected =
            selectedCurrency instanceof Token &&
            selectedCurrency.address === token.address;
          return (
            <BaseWrapper
              onClick={() => !selected && onSelect(token)}
              disable={selected}
              key={token.address}
            >
              <CurrencyLogo
                currency={token}
                style={{ borderRadius: '50%', marginRight: 8 }}
              />
              <Text>{token.symbol}</Text>
            </BaseWrapper>
          );
        })}
      </AutoRow>
    </AutoColumn>
  );
}
