/* eslint-disable @next/next/no-img-element */
import { Currency } from '@vapordex/sdk';
import { Flex } from '@vapordex/uikit';
import { Typography } from '@vaporfi/uikit';
import Image from 'next/image';
import { useMemo } from 'react';
import { NETWORK_ICON } from '../Menu/NetworkSelector/networks';
import { getTokenLogoURL } from '@vaporfi/utils';

export default function CurrencyLogo({
  currency,
  showName = false,
  size = 32,
  style = { borderRadius: '50%' },
}: {
  currency?: Currency;
  size?: number;
  style?: React.CSSProperties;
  showName?: boolean;
}) {
  const src = useMemo(() => {
    if (currency?.isNative) return NETWORK_ICON?.[currency?.chainId];

    return getTokenLogoURL(currency);
  }, [currency]);

  if (currency?.isNative) {
    return (
      <Image
        src={src}
        width={size}
        height={size}
        style={style}
        alt="native-currency-logo"
        className="rounded-full"
      />
    );
  }

  return (
    <Flex justifyContent="center" alignItems="center">
      <img
        className="rounded-full"
        width={size}
        height={size}
        src={src}
        alt={`${currency?.symbol ?? 'token'} logo`}
        style={style}
      />
      {showName && (
        <Typography color="light">{currency?.name ?? 'token'}</Typography>
      )}
    </Flex>
  );
}
